import { LicenceMappingDto } from "core/models/licenceMapping";
import { OrgUnitDto } from "core/models/orgUnit";
import { TenantProviderDto } from "core/models/tenantProvider";
import {
  AppointmentTypeTGArgs,
  AppointmentTypeTGDto
} from "modules/booking/models";
import { stringify } from "query-string";

import { AxiosInstance } from "@bps/http-client";

export class TenantGateway {
  constructor(private api: AxiosInstance) {}
  public getOrgUnits = async (): Promise<OrgUnitDto[]> =>
    (await this.api.get<OrgUnitDto[]>("client/orgUnit")).data;

  public getTenantProviders = async (): Promise<TenantProviderDto[]> =>
    (await this.api.get<TenantProviderDto[]>("client/provider")).data;

  public getLicenceMappings = async (): Promise<LicenceMappingDto[]> =>
    (await this.api.get<LicenceMappingDto[]>("licenceMapping")).data;

  public getAppointmentTypesTG = async (
    args?: AppointmentTypeTGArgs
  ): Promise<AppointmentTypeTGDto[]> => {
    return (
      await this.api.get<AppointmentTypeTGDto[]>(
        `appointmentTypes?${stringify(args!)}`
      )
    ).data;
  };
}
