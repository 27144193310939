import { UserDto } from "core/models";
import {
  UserPreferenceArgs,
  UserPreferenceDto,
  UserPreferenceModel
} from "core/models/userPreference.model";
import { stringify } from "query-string";

import { AxiosInstance } from "@bps/http-client";

export class UserGateway {
  constructor(private api: AxiosInstance) {}

  public getUserPreferences = async (
    args: UserPreferenceArgs
  ): Promise<UserPreferenceModel[]> => {
    const data = (
      await this.api.get<UserPreferenceDto[]>(
        `userpreferences?${stringify(args)}`
      )
    ).data;
    return data.map(x => new UserPreferenceModel(x));
  };

  public getCurrentUser = async (): Promise<UserDto> =>
    (await this.api.get<UserDto>("users/me")).data;
}
