import { PageViewTracker } from "PageViewTracker";

import { ErrorBoundaryFallback } from "common/ErrorBoundaryFallback";
import { StoresProvider } from "common/StoresProvider";
import { ErrorAlert } from "common/ui-components";
import { initAppInsights } from "core/analytics/app-insights/app-insights.utils";
import { initGoogleAnalytics } from "core/analytics/google-analytics/google-analitics.utils";
import { initHotJar } from "core/analytics/hotjar/hot-jar.utils";
import { config } from "core/config";
import { ReactQueryProvider } from "core/ReactQueryProvider";
import { Shell } from "layout/Shell";
import { configure } from "mobx";
import promiseFinally from "promise.prototype.finally";
import { ErrorBoundary } from "react-error-boundary";
import { ReactQueryDevtools } from "react-query/devtools";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import {
  CenteredLargeSpinner,
  defaultTheme,
  globalAppStyles,
  initializeIcons,
  mergeStyles,
  scopedSettings,
  ThemeProvider
} from "@bps/fluent-ui";
import { AuthenticationProvider } from "@bps/http-client";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";

import "./common/reset.css";

// observables can only be mutated within actions
configure({ enforceActions: "observed" });

promiseFinally.shim();

// Fluent UI and NP custom icons ref: https://devinfrasa.z8.web.core.windows.net/?path=/story/foundations-icons--icons-stories
initializeIcons();

initGoogleAnalytics();
initHotJar();
const appInsightPlugin = initAppInsights();

const redirectUri = `${window.location.origin}${config.msal.postAuthenticateRedirectUrl}`;

export const App = () => {
  return (
    <ThemeProvider
      className={mergeStyles({
        height: "100%",
        selectors: globalAppStyles
      })}
      theme={{ ...defaultTheme, components: scopedSettings }}
    >
      <AuthenticationProvider
        config={{
          ...config.msal,
          redirectUri,
          postAuthenticateRedirectUrl: redirectUri
        }}
        renderSpinner={message => (
          <CenteredLargeSpinner label={message} labelPosition="bottom" />
        )}
        renderError={message => <ErrorAlert error={message} />}
      >
        <AppInsightsContext.Provider value={appInsightPlugin}>
          <StoresProvider>
            <ReactQueryProvider>
              <Router>
                <>
                  <Routes>
                    <Route
                      path="*"
                      element={
                        <ErrorBoundary
                          FallbackComponent={ErrorBoundaryFallback}
                        >
                          <Shell />
                        </ErrorBoundary>
                      }
                    />
                  </Routes>

                  <PageViewTracker />
                </>
              </Router>

              {config.environment && import.meta.env.MODE === "development" && (
                <ReactQueryDevtools />
              )}
            </ReactQueryProvider>
          </StoresProvider>
        </AppInsightsContext.Provider>
      </AuthenticationProvider>
    </ThemeProvider>
  );
};
