import { routes } from "common/routes";
import {
  Help,
  LocationPicker,
  NavItem,
  UserProfile
} from "common/ui-components";
import { WhenFeatureToggled } from "core/WhenFeatureToggled";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import {
  ActionButton,
  getColorFromString,
  Header,
  IButtonStyles,
  IStyle,
  ITheme,
  Stack,
  updateA,
  useTheme
} from "@bps/fluent-ui";

const getOpacityColor = (theme: ITheme, opacity: number) =>
  updateA(getColorFromString(theme.palette.neutralLight)!, opacity).str;

export const navBarActionButtonStyles = (
  theme: ITheme,
  isCurrent?: boolean
): Partial<IButtonStyles> => {
  const style: IStyle = { color: theme.palette.white };

  return {
    icon: style,
    menuIcon: style,
    iconHovered: style,
    iconPressed: style,
    root: { background: isCurrent ? getOpacityColor(theme, 25) : "inherit" }
  };
};

const NavBarNavItem = ({
  to,
  text,
  iconName,
  tab
}: {
  to: string;
  text: string;
  iconName: string;
  tab: string;
}) => {
  const theme = useTheme();

  // Split string to find first part of url. For example with "/booking/pratice-profile" we find "/booking"
  const isCurrent = tab.split("/")[1] === to.toString().split("/")[1];

  return (
    <NavItem to={to}>
      {({ to }) => (
        <Link to={to}>
          <ActionButton
            text={text}
            iconProps={{ iconName }}
            styles={navBarActionButtonStyles(theme, isCurrent)}
          />
        </Link>
      )}
    </NavItem>
  );
};

export const NavBar = observer(() => {
  const navigate = useNavigate();
  const location = useLocation();
  const [tab, changeTab] = useState<string>(location.pathname);

  // Hook to update navbar tab if page has changed
  useEffect(() => {
    if (tab === location.pathname) return;

    changeTab(location.pathname);
  }, [location.pathname, tab]);

  return (
    <>
      <Header
        hoverEffectItems
        logoProps={{
          text: "Premier Online Services",
          onClick: () => {
            navigate(routes.appointmentBooking.locationEdit);
          }
        }}
        userBox={<UserProfile />}
        styles={{ root: { minHeight: "auto" } }}
      >
        <NavBarNavItem
          to={routes.appointmentBooking.locationEdit}
          text="Online configuration"
          iconName="Settings"
          tab={tab}
        />

        <WhenFeatureToggled>
          <NavBarNavItem
            to={routes.premierOnlineServices.basePath}
            text="Assign licences"
            iconName="AddFriend"
            tab={tab}
          />
        </WhenFeatureToggled>
        <Stack
          horizontal
          styles={{
            root: {
              flexGrow: 1
            }
          }}
        />
        <Help />
        <LocationPicker />
      </Header>
    </>
  );
});
