import { ErrorAlert } from "common/ui-components/Alert";
import { FunctionComponent } from "react";
import { FallbackProps } from "react-error-boundary";

export const ErrorBoundaryFallback: FunctionComponent<FallbackProps> = ({
  error,
  children
}) => {
  if (error) {
    return <ErrorAlert showReloadLink={true} />;
  }
  return <>{children}</>;
};
