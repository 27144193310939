import {
  checkLocationBaseInterval,
  checkProviderBaseInterval
} from "common/utils";
import {
  useLocationAppointmentTypesQuery,
  useLocationQuery
} from "core/gateways/practice/LocationHooks";
import { useLocationProvidersQuery } from "core/gateways/practice/ProviderHooks";
import { useUserPreferencesQuery } from "core/gateways/user/UserHooks";
import { observer } from "mobx-react-lite";

import { BaseIntervalWarningMessageBar } from "./appointment-types-base-interval-warning-message-bar";

interface BaseIntervalWarningProps {
  locationId: string;
}

export const BaseIntervalWarning = observer(
  ({ locationId }: BaseIntervalWarningProps) => {
    const { data: location } = useLocationQuery(locationId);
    const { data: providers } = useLocationProvidersQuery(locationId);
    const { data: appointmentTypes } =
      useLocationAppointmentTypesQuery(locationId);

    const { data: userPreferences } = useUserPreferencesQuery();

    if (!(providers?.length && appointmentTypes?.length && location))
      return null;

    // Check if any appointment type duration is not a interval of the base appointment type duration.
    // If true, we will display a alert banner across PrOS
    const baseInterval: number = Number(
      userPreferences?.find(
        x => x.settingName.toLowerCase() === "appointmentlength"
      )?.settingValue
    );

    // Check Location Customised appointment types
    const isLocationBaseInterval =
      appointmentTypes.filter(at => checkLocationBaseInterval(at, baseInterval))
        .length > 0;

    // Check Provider Customised appointment types
    const isProviderBaseInterval =
      providers.filter(
        p =>
          p.appointmentTypes.filter(pat =>
            checkProviderBaseInterval(pat, baseInterval)
          ).length > 0
      ).length > 0;

    return (
      <>
        {(isLocationBaseInterval || isProviderBaseInterval) && (
          <BaseIntervalWarningMessageBar />
        )}
      </>
    );
  }
);
